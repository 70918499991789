.conteggio {
    font-weight: bold;
    color: green;
    display: inline;
}

.collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}


.impersonificationIcon {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    animation: pulse 2s infinite;
    color: #9370db;
    margin: 0;
}

.impersonificationIcon:hover {
    animation: none;
}


@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(147, 112, 219, 0.4);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(147, 112, 219, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(147, 112, 219, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(147, 112, 219, 0.4);
        box-shadow: 0 0 0 0 rgba(147, 112, 219, 0.4);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(147, 112, 219, 0);
        box-shadow: 0 0 0 10px rgba(147, 112, 219, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(147, 112, 219, 0);
        box-shadow: 0 0 0 0 rgba(147, 112, 219, 0);
    }
}

.filepond--credits {
    display: none;
}

body {
    overflow: hidden;
    margin: 0;
    padding: 0;
}